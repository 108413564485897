.three-quarter-input{
    flex: 75%;
}
.quarter-input{
    flex: 25%;
}
.half-input {
    flex: 50%;
}

.register-flex{
    display: flex;
}
