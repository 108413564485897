.cart-img {
    font-size: 35px;
}

.cart-item-count {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    height: 20px;
    width: 20px;
    font-size: 10px;
    color: white;
    
}

.user-img {
    font-size: 30px;
    margin-right: 10px;
}