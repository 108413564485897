.input {
    height: 56px;
    color: #959595;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    border: 1px solid #AEAEAE;
    border-radius: 4px;
    width: 100%;
    margin-bottom: 10px;
    padding-left:10px;
}
