.category-image{
    max-width: 100%;
}

.categories-area *{
    margin-right: 1%;
}

.categories-area *:last-child {
    margin-right: 0;                                                                                
}
