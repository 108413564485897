.keila-body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.keila-content{
    flex: 1;
}

.disable-scroll{
    overflow: hidden;
}