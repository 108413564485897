.category-image-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.category-title-area {
    width: 100%;
    margin-top: auto;
}

