.carousel-caption{
    display: flex !important;
    top: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
}

.slide, .carousel-inner, .carousel-item{
    height: 100%;
    width: 100%;
}

.carousel-img{
  width: 100%;
  height: 80vh;
  background-size: cover;
  position: relative;
}

.carousel-img:before {
  content: "";
  position: absolute;
  left: 0; right: 0;
  top: 0; bottom: 0;
  background: rgba(0,0,0,.5);
}

.animate__animated.animate__zoomIn {
  --animate-duration: 3s;
}