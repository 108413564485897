.width-70{
    width: 70%
}

.width-30 {
    width: 30%
}

.align-center{
    align-items: center;
}

.flex-start{
    align-self: flex-start;
}

.height-100{
    height: 100px;
}

.delivery-methods, .pay-methods {
    list-style-type: none;
}