.cart-overlay {
    width: 100%;
    height: 100%;
    animation: visible 0.5s ease forwards;
    z-index: 3;
}

.cart-inner {
    animation: slidein 1.2s ease forwards;
    right: 0;
    top: 0;
    width: 30%;
    height: 100%;
    background-color: #ececec;
    overflow-y: scroll;
}

.cart-title{
    width: 80%;
    font-size: 12px;
    letter-spacing: 1px;
}

.button-rounded {
    border: 1px solid #110264;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.close-area {
    font-size: 20px;
    width: 20%;
}

.cart-item-title, .cart-item-close{
    width: 50%;
}

.fixed-product-height{
    height: 60px;
}

.cart-second-item{
    width: 90%;
    margin-top: 50px;
}
.clear-cart-items{
    background-color: white;
}
@keyframes visible {
    from {
        background-color: rgba(0,0,0,0);
    }

    to {
        background-color: rgba(0,0,0,0.4);
    }
    
}

@keyframes slidein {
    from {
        width: 0%;
    }

    to {
        width: 30%;
    }
}