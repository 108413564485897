.quantity-in-cart{
    width: 40px;
    text-align: center;
}

.promo-container {
    border-radius: 10%;
    font-size: 8px;
    padding: 2px;
}

.add-to-cart, .cart-handle {
    height: 30px;
    font-size: 10px;
    background-color: #ffffff;
    color: black;
}



.add-to-cart:hover {
    background-color: #039A66;
    color: #F9F9F9;
}

.add, .subtract, .quantity-in-cart {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
}

    .subtract, .quantity-in-cart{
        border-left: none;
    }

.add, .quantity-in-cart {
    border-right: none;
}

    .add {
        margin-right: -1px;
        border-left: 1px solid black;
    }

.subtract {
    margin-left: -1px;
    border-right: 1px solid black;
}

.quantity-in-cart:focus{
    outline: none;
}