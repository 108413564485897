
.login-btn {
    color: white;
    text-align: center;
    border: 1.5px solid #A5A5A5;
    cursor: pointer;
    height: 56px;
    border-radius: 28px;
    font-size: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    background-color: #110264;
    margin-top: 50px;
}
.login-link-div{
    text-align: right;
}

.login-options{
    display: flex;
    justify-content: center ;
    padding: 50px 0;
}

.login-options img {
    margin: 0 10px;
}