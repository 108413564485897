.banner-area{
    width: 100%;
    height: 50vh;
}

.left-area{
    width: 75%;
    height: 100%;
}


.right-area {
    width: 25%;
    height: 100%;
}
.fixed-banner, .last-item-visited{
    height: 50%;
    width: 100%;
}
.fixed-banner{
    background-image: url('/assets/images/covid-19.gif');
    background-size: cover;
    background-repeat: no-repeat;
}
.last-item-visited {
    background-color: white;
}