.round-border-area, .round-border-area:focus, .round-border-area {
    border: 2px solid #022464;
    border-radius: 50px;
    outline-offset: 0;
    outline: none;
}

input.search-input {
    caret-color: #025564;
}
.variable-width {
    width: 60%;
}

.full-width {
    width: 100%;
}
.search-input {
    padding: 7.5px 20px;
}

.search-image{
    height: 40px;
}


.search-result {
    top: 40px;
    max-height: 38vh;
    background-color: #ececec;
    overflow-y: scroll;
    justify-content: center;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.search-item-tilte{
    width: 40%;
}

.button-style {
    background: #110264;
    color: white;
    padding: 5px 17px;
    border-radius: 21px;
    border: none;
    font-size: 12px;
}

.button-style:focus{
    outline: none;
}